//////////////////////////////////////////////////////////////////////
// Configurations related to search                                 //
// Note: some search experience is also on defaultMapsConfig        //
// and defaultListingConfig.js is responsible public data filtering //
//////////////////////////////////////////////////////////////////////

// NOTE: if you want to change the structure of the data,
// you should also check src/util/configHelpers.js
// some validation is added there.

// Main search used in Topbar.
// This can be either 'keywords' or 'location'.
export const mainSearch = {
    searchType: 'location',
  };
  
  /**
   * Configuration for default filters.
   * These are custom configs for each filter.
   * Common properties: key, schemaType, and label.
   * Note: the order of default filters is defined in util/configHelpers.js
   */
  
  export const dateRangeFilter = {
    schemaType: 'dates',
    // Should the entire date range be available, or just part of it
    // Note: Since we don't enforce location search for dates filtering,
    //       we don't use API's 'time-full' in actual queries. It would require time zone info.
    availability: 'time-full', // time-partial
    // Options: day/night. This affects counting and whether single day picking is possible.
    dateRangeMode: 'day',
  };
  
  export const priceFilter = {
    schemaType: 'price',
    // Note: unlike most prices this is not handled in subunits
    min: 0,
    max: 1000,
    step: 5,
  };
  // // This is not in use by default.
  // export const keywordsFilter = {
  //   key: 'keywords',
  //   schemaType: 'keywords',
  // }

  export const filters = [
    {
      id: 'features',
      label: 'Amenities',
      type: 'FeaturesFilter',
      group: 'primary',
      queryParamNames: ['pub_features'],
      config: {
        searchMode: 'has_any',
        options: [
          { key: 'airConditioner',        label: 'Air Conditioner' },
          { key: 'autoclave',             label: 'Autoclave' },
          { key: 'CBCTScanner',           label: 'CBCT Scanner' },
          { key: 'dentalChair',           label: 'Dental Chair' },
          { key: 'desk',                  label: 'Desk' },
          { key: 'hypnotherapyChair',     label: 'Hypnotherapy Chair' },
          { key: 'iTeroScanner',          label: 'iTero Scanner' },
          { key: 'kitchenFacilities',     label: 'Kitchen Facilities' },
          { key: 'lockers',               label: 'Lockers' },
          { key: 'magnifyingLamp',        label: 'Magnifying Lamp' },
          { key: 'massageChair',          label: 'Massage Chair' },
          { key: 'medicalFridge',         label: 'Medical Fridge' },
          { key: 'otherIntraoralScanner', label: 'Other Intraoral Scanner' },
          { key: 'parkingStreet',         label: 'Parking (On Street)' },
          { key: 'parkingPrivate',        label: 'Parking (Private)' },
          { key: 'patientLounge',         label: 'Patient Lounge' },
          { key: 'printer',               label: 'Printer' },
          { key: 'receptionist',          label: 'Receptionist' },
          { key: 'sharpsClinicalWaste',   label: 'Sharps & Clinical Waste' },
          { key: 'sink',                  label: 'Sink' },
          { key: 'sofaCouchRecliner',     label: 'Sofa / Couch / Recliner' },
          { key: 'teaCoffeeFacilities',   label: 'Tea / Coffee Facilities' },
          { key: 'treatmentTable',        label: 'Treatment Table' },
          { key: 'trearmentTableFace',    label: 'Treatment Table (Face Hole)' },
          { key: 'tv',                    label: 'Tv' },
          { key: 'washableFloor',         label: 'Washable Floor' },
          { key: 'wheelchairAccess',      label: 'Wheelchair Access' },
          { key: 'wiFi',                  label: 'WiFi' },
        ],
      },
    },
    {
      id: 'requirements',
      label: 'Requirements',
      type: 'SelectMultipleFilter',
      group: 'secondary',
      queryParamNames: ['pub_requirements'],
      config: {
        searchMode: 'has_all',
        options: [
          { key: 'doctor',            label: 'Doctor' },
          { key: 'pharmacist',        label: 'Pharmacist' },
          { key: 'paramedic',         label: 'Paramedic' },
          { key: 'physiotherapist',   label: 'Physiotherapist' },
          { key: 'osteopath',         label: 'Osteopath' },
          { key: 'dentist',           label: 'Dentist' },
          { key: 'dentalHygienist',   label: 'Dental Hygienist' },
          { key: 'midwife',           label: 'Midwife' },
          { key: 'chiropractor',      label: 'Chiropractor' },
          { key: 'nonMedic',          label: 'Non Medic' },
          { key: 'nursePractitioner', label: 'Nurse Practitioner' },
          { key: 'dentalTherapist',   label: 'Dental Therapist' },
          { key: 'psychologist',      label: 'Psychologist' },
          { key: 'optometrist',       label: 'Optometrist' },
        ],
      },
    },
    {
      id: 'clinician',
      label: 'Clinician Type',
      type: 'ClinicianTypeFilter',
      group: 'primary',
      queryParamNames: ['pub_clinician'],
      config: {
        schemaType: 'enum',
        options: [
          { key: 'chiropractor',      label: 'Chiropractor' },
          { key: 'dentalHygienist',   label: 'Dental Hygienist' },
          { key: 'dentalTherapist',   label: 'Dental Therapist' },
          { key: 'dentist',           label: 'Dentist' },
          { key: 'doctor',            label: 'Doctor' },
          { key: 'midwife',           label: 'Midwife' },
          { key: 'nonMedic',          label: 'Non Medic' },
          { key: 'nursePractitioner', label: 'Nurse Practitioner' },
          { key: 'optometrist',       label: 'Optometrist' },
          { key: 'osteopath',         label: 'Osteopath' },
          { key: 'paramedic',         label: 'Paramedic' },
          { key: 'pharmacist',        label: 'Pharmacist' },
          { key: 'physiotherapist',   label: 'Physiotherapist' },
          { key: 'psychologist',      label: 'Psychologist' },
          { key: 'other',             label: 'Other'},
        ],
      },
    },
    {
      id: 'title',
      label: 'Title',
      type: 'TitleFilter',
      group: 'primary',
      queryParamNames: ['pub_title'],
      config: {
        schemaType: 'enum',
        options: [
          { key: 'dr',    label: 'Dr' },
          { key: 'mrs',   label: 'Mrs' },
          { key: 'miss',  label: 'Miss' },
          { key: 'mr',    label: 'Mr' },
        ],
      },
    },
    {
      id: 'registrationBody',
      label: 'RegistrationBody',
      type: 'SelectSingleFilter',
      group: 'secondary',
      queryParamNames: ['pub_registrationBody'],
      config: {
        schemaType: 'enum',
        options: [
          { key: 'GMC',   label: 'GMC' },
          { key: 'GDC',   label: 'GDC' },
          { key: 'GPhC',  label: 'GPhC' },
          { key: 'NMC',   label: 'NMC' },
          { key: 'HCPC',  label: 'HCPC' },
          { key: 'GCC',   label: 'GCC' },
          { key: 'GOC',   label: 'GOC' },
          { key: 'GOsC',  label: 'GOsC' },
          { key: 'other', label: 'Other'},
        ],
      },
    },
    {
      id: 'decleration',
      label: 'Decleration',
      type: 'SelectSingleFilter',
      group: 'secondary',
      queryParamNames: ['pub_capacity'],
      config: {
        schemaType: 'enum',
        options: [
          { key: 'y',   label: 'Yes' },
          { key: 'n',   label: 'No' },
        ],
      },
    },
    {
      id: 'certificate',
      label: 'CQC Registered',
      type: 'CQCRegisteredFilter',
      group: 'primary',
      queryParamNames: ['pub_certificate'],
      config: {
        schemaType: 'enum',
        // "key" is the option you see in Flex Console.
        // "label" is set here for the UI only.
        // Note: label is not added through the translation files
        // to make filter customizations a bit easier.
        options: [
          { key: 'Y', label: 'Yes' },
          { key: 'N', label: 'No' },
        ],
      },
    },
    {
      id: 'clinicType',
      label: 'Clinic Type',
      type: 'ClinicTypeFilter',
      group: 'primary',
      queryParamNames: ['pub_clinicType'],
      config: {
        schemaType: 'enum',
        // "key" is the option you see in Flex Console.
        // "label" is set here for the UI only.
        // Note: label is not added through the translation files
        // to make filter customizations a bit easier.
        options: [
          { key: 'beauty', label: 'Beauty Clinic' },
          { key: 'dental', label: 'Dental Clinic' },
          { key: 'gp', label: 'GP Clinic' },
          { key: 'medical', label: 'Medical Clinic' },
          { key: 'pharmacy', label: 'Pharmacy' },
          { key: 'physio', label: 'Physiotherapy Clinic' },
          { key: 'salon', label: 'Salon' },
          { key: 'therapy', label: 'Therapy Clinic' },
        ],
      },
    },
    {
      id: 'spaceType',
      label: 'Space Type',
      type: 'SpaceTypeFilter',
      group: 'primary',
      queryParamNames: ['pub_spaceType'],
      config: {
        schemaType: 'enum',
        // "key" is the option you see in Flex Console.
        // "label" is set here for the UI only.
        // Note: label is not added through the translation files
        // to make filter customizations a bit easier.
        options: [
          { key: 'consultation', label: 'Consultation Space' },
          { key: 'treatment', label: 'Treatment Space' },
        ],
      },
    },
  ];
  
  export const sortConfig = {
    // Enable/disable the sorting control in the SearchPage
    active: true,
  
    // Note: queryParamName 'sort' is fixed,
    // you can't change it since Marketplace API expects it to be named as 'sort'
    queryParamName: 'sort',
  
    // Internal key for the relevance option, see notes below.
    relevanceKey: 'relevance',
  
    // Relevance key is used with keywords filter.
    // Keywords filter also sorts results according to relevance.
    relevanceFilter: 'keywords',
  
    // Keyword filter is sorting the results by relevance.
    // If keyword filter is active, one might want to disable other sorting options
    // by adding 'keywords' to this list.
    conflictingFilters: [],
  
    options: [
      // These are default sort options
      { key: 'createdAt', labelTranslationKey: 'SortBy.newest' },
      { key: '-createdAt', labelTranslationKey: 'SortBy.oldest' },
      { key: '-price', labelTranslationKey: 'SortBy.lowestPrice' },
      { key: 'price', labelTranslationKey: 'SortBy.highestPrice' },
      // If you add own sort options, you can also use label key: { key: 'meta_rating', label: 'Highest rated' },
  
      // The relevance is only used for keyword search, but the
      // parameter isn't sent to the Marketplace API. The key is purely
      // for handling the internal state of the sorting dropdown.
      {
        key: 'relevance',
        labelTranslationKey: 'SortBy.relevance',
        labelTranslationKeyLong: 'SortBy.relevanceLong',
      },
    ],
  };
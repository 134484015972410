import { getAllTransitionsForEveryProcess } from "../transactions/transaction";
import { getStateData } from "../containers/InboxPage/InboxPage.stateData";
import { TX_TRANSITION_ACTOR_CUSTOMER, TX_TRANSITION_ACTOR_PROVIDER } from "../transactions/transaction";
import { storableError } from "../util/errors";

// Action types
export const FETCH_NOTIFICATION_COUNT_REQUEST = 'app/notifications/FETCH_NOTIFICATION_COUNT_REQUEST';
export const FETCH_NOTIFICATION_COUNT_SUCCESS = 'app/notifications/FETCH_NOTIFICATION_COUNT_SUCCESS';
export const FETCH_NOTIFICATION_COUNT_ERROR = 'app/notifications/FETCH_NOTIFICATION_COUNT_ERROR';
export const UPDATE_NOTIFICATION_COUNT = 'app/notifications/UPDATE_NOTIFICATION_COUNT';
export const MARK_NOTIFICATION_AS_READ = 'app/notifications/MARK_NOTIFICATION_AS_READ';

// Initial state
const initialState = {
    notificationCount: 0,
    fetchInProgress: false,
    fetchError: null,
    notificationData: {},
};

// Reducer
export default function notificationsReducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case UPDATE_NOTIFICATION_COUNT:
            return { ...state, notificationCount: payload };
        case FETCH_NOTIFICATION_COUNT_REQUEST:
            return { ...state, fetchInProgress: true, fetchError: null };
        case FETCH_NOTIFICATION_COUNT_SUCCESS:
            return { ...state, notificationCount: payload.notificationCount, notificationData: payload.notificationData, fetchInProgress: false };
        case FETCH_NOTIFICATION_COUNT_ERROR:
            return { ...state, fetchError: payload, fetchInProgress: false };
        case MARK_NOTIFICATION_AS_READ: {
            return {
                ...state,
                lastUpdated: new Date().getTime(),
            };
        }
        default:
            return state;
    }
}

// Actions creators
export const updateNotificationCount = (count) => ({
    type: UPDATE_NOTIFICATION_COUNT,
    payload: count,
});

export const fetchNotificationCountRequest = () => ({
    type: FETCH_NOTIFICATION_COUNT_REQUEST,
});

export const fetchNotificationCountSuccess = (notificationCount, notificationData) => ({
    type: FETCH_NOTIFICATION_COUNT_SUCCESS,
    payload: {notificationCount, notificationData},
});

export const fetchNotificationCountError = (error) => ({
    type: FETCH_NOTIFICATION_COUNT_ERROR,
    payload: error,
    error: true,
});

export const markNotificationAsRead = (transactionId) => (dispatch, getState) => {
    const currentUser = getState().user.currentUser;
    const userId = currentUser.id.uuid;

    const currentTime = new Date().getTime();
    localStorage.setItem(`last-viewed-${transactionId}-${userId}`, currentTime.toString());    

    dispatch({
        type: MARK_NOTIFICATION_AS_READ,
        payload: transactionId,
    });
};

// Thunk Action to Fetch Notification Count
const determineIfNotificationNeeded = (tx, txMessages, txReviews, stateData, userId) => {
    const isBrowser = typeof window !== 'undefined';
    if (!isBrowser) {
      return false;
    }
    
    const lastViewedKey = `last-viewed-${tx.id.uuid}-${userId}`;
    const lastViewedTime = parseInt(localStorage.getItem(lastViewedKey) || '0', 10);

     // Filter messages and reviews sent by others
    const messagesFromOthers = txMessages.filter(message => message.relationships.sender.data.id.uuid !== userId);
    const reviewsFromOthers = txReviews.filter(review => review.relationships.author.data.id.uuid !== userId);
  
    const lastMessage = messagesFromOthers.length > 0 ? messagesFromOthers[messagesFromOthers.length - 1] : null;
    const lastReview = reviewsFromOthers.length > 0 ? reviewsFromOthers[reviewsFromOthers.length - 1] : null;
  
    const lastMessageTime = lastMessage ? new Date(lastMessage.attributes.createdAt).getTime() : 0;
    const lastReviewTime = lastReview ? new Date(lastReview.attributes.createdAt).getTime() : 0;
    
    const bookingTransitionName = 'transition/confirm-payment';
    const isBooked = tx.attributes.lastTransition === bookingTransitionName;
    const bookingTransitionTime = isBooked ? new Date(tx.attributes.lastTransitionedAt).getTime() : 0;

    const newActivityTime = Math.max(lastMessageTime, lastReviewTime, bookingTransitionTime);
  
    const notificationVisible = newActivityTime > lastViewedTime;
  
    return notificationVisible;
};

export const fetchNotificationCount = (intl) => (dispatch, getState, sdk) => {
    dispatch(fetchNotificationCountRequest());

    const state = getState();
    const currentUser = state.user.currentUser;
    const userId = currentUser.id.uuid;

    const apiQueryParams = (only) => ({
        only,
        user_Id: userId,
        lastTransition: getAllTransitionsForEveryProcess(),
        include: [
            'customer',
            'customer.profileImage',
            'provider',
            'provider.profileImage',
            'listing',
            'booking',
            'reviews',
            'messages',
            'messages.sender',
            'participants',
        ],
        'fields.transaction': [
            'processName',
            'lastTransition',
            'lastTransitionedAt',
            'transitions',
            'payinTotal',
            'payoutTotal',
            'lineItems',
            'messages',
            'reviews.author',
            'reviews.provider',
        ],
        'fields.listing': ['title', 'availabilityPlan', 'publicData.listingType'],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
        page: 1,
        perPage: 100,
    });

    const fetchTransactions = (only) => sdk.transactions.query(apiQueryParams(only));

    return Promise.all([
        fetchTransactions('order'),
        fetchTransactions('sale'),
    ])
        .then(([ordersResponse, salesResponse]) => {
            const transactions = [
                ...ordersResponse.data.data,
                ...salesResponse.data.data,
            ];

            const ordersIncluded = ordersResponse.data.included || [];
            const salesIncluded = salesResponse.data.included || [];
            const included = [
                ...ordersIncluded,
                ...salesIncluded,
            ];

            // Build message map with sender data
            const messageMap = {};
            included.filter(e => e.type === 'message').forEach(message => {
                messageMap[message.id.uuid] = message;
            });

            // Build review map with author data
            const reviewMap = {};
            included.filter(e => e.type === 'review').forEach(review => {
            reviewMap[review.id.uuid] = review;
            });

            // Build user map to resolve sender and author details
            const userMap = {};
            included.filter(e => e.type === 'user').forEach(user => {
            userMap[user.id.uuid] = user;
            });

            let notificationCount = 0;
            let notificationData = {};

            transactions.forEach((tx) => {
                const isCustomer = tx.relationships.customer?.data?.id?.uuid === userId;
                const isProvider = tx.relationships.provider?.data?.id?.uuid === userId;

                if (!isCustomer && !isProvider) {
                    return;
                }

                const transactionRole = isCustomer ? TX_TRANSITION_ACTOR_CUSTOMER : TX_TRANSITION_ACTOR_PROVIDER;

                let stateData = null;
                try {
                    stateData = getStateData({ transaction: tx, transactionRole, intl });
                } catch (error) {
                    console.error('Error in getStateData:', error);
                    return;
                }

                // Resolve messages and reviews
                const messageRefs = tx.relationships.messages?.data || [];
                const txMessages = messageRefs.map(ref => messageMap[ref.id.uuid]).filter(m => !!m);

                const reviewRefs = tx.relationships.reviews?.data || [];
                const txReviews = reviewRefs.map(ref => reviewMap[ref.id.uuid]).filter(r => !!r);

                // Determine if a notification is needed
                const notificationNeeded = determineIfNotificationNeeded(tx, txMessages, txReviews, stateData, userId);

                notificationData[tx.id.uuid] = notificationNeeded;

                if (notificationNeeded) {
                    notificationCount += 1;
                }
            });


            dispatch(fetchNotificationCountSuccess(notificationCount, notificationData));
        })
        .catch((e) => {
            console.error('Error fetching transactions:', e);
            if (e.response && e.response.data) {
                console.error('API response error:', JSON.stringify(e.response.data. null, 2));
            }
            dispatch(fetchNotificationCountError(storableError(e)));
        });
};